import React from 'react';
// add css
import './BudibaseForm.scss';

const BudibaseForm = ({ iframeId = 'budibase-form', budibaseFormLink = '', width = 800, height = 800, MT = '0', MB = '0' }) => {
  return (
    <div>
      <div className="iframe-wrapper-bb" style={{ marginTop: `${MT}`, marginBottom: `${MB}` }}>
        <iframe
          title={iframeId}
          width={width}
          height={height}
          frameBorder="0"
          allow="clipboard-write;camera;geolocation;fullscreen"
          src={budibaseFormLink}
        ></iframe>
        <div className="iframe-overlay-bottom-bb"></div>
      </div>
    </div>
  );
};

export default BudibaseForm;
