import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Page from 'Components/Routing/Page';
import RootPage from 'Pages/Root/RootPage';
import EthBoard from 'Pages/ETH-Rat/EthBoard';
import Umfrage from 'Pages/ETH-Rat/Forms/Umfrage/Umfrage';

const RoutingManager = () => {
  return (
    <Routes>
      <Route path="/" element={<Page component={RootPage} title="IVIA Lab Workshops" />} />
      <Route path="/eth-rat" element={<Page component={EthBoard} title="ETH Rat Workshop" />} />
      <Route path="/eth-rat/umfrage" element={<Page component={Umfrage} title="ETH Rat Workshop Umfrage" />} />
    </Routes>
  );
};

export default RoutingManager;
