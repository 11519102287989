// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.iframe-wrapper-bb {
  position: relative;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.iframe-wrapper-bb .iframe-overlay-bottom-bb {
  height: 80px;
  width: 100%;
  background-color: white;
  position: absolute;
  bottom: 0;
  left: 0;
}
.iframe-wrapper-bb iframe {
  width: 100%;
  border: none;
  max-width: 800px;
}`, "",{"version":3,"sources":["webpack://./src/components/ContactForm/BudibaseForm/BudibaseForm.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,YAAA;EAEA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,YAAA;AAAF;AAEE;EACE,YAAA;EACA,WAAA;EACA,uBAAA;EACA,kBAAA;EACA,SAAA;EACA,OAAA;AAAJ;AAGE;EACE,WAAA;EACA,YAAA;EACA,gBAAA;AADJ","sourcesContent":[".iframe-wrapper-bb {\n  position: relative;\n  margin: auto;\n  // center iframe in div: \n  display: flex;\n  justify-content: center;\n  align-items: center;\n  height: 100%;\n\n  .iframe-overlay-bottom-bb {\n    height: 80px;\n    width: 100%;\n    background-color: white;\n    position: absolute;\n    bottom: 0;\n    left: 0;\n  }\n\n  iframe {\n    width: 100%;\n    border: none;\n    max-width: 800px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
