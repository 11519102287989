import React from 'react';
import session1 from './intro_to_ai.jpg';
import session2 from './ai_tools.png';
import session3 from './trust_ai.jpeg';
import session4 from './best_practices_ai.webp';
import miro_logo from './miro-logo.jpg';
import WorkInProgress from 'Components/WorkInProgress';
import slido from './slido.png';

import IVIA_LOGO from 'Logos/ivia-logo-static.png';
import './EthBoard.scss';
import { Card, Col, Flex, Layout, Row, Typography } from 'antd';

const { Meta } = Card;
const { Header, Footer, Content } = Layout;

const sessionsData = {
  session1: {
    path: 'https://workshops.ivia.ch/eth-rat/session1/',
    image: session1,
    description: 'What is AI, how does it work, and what can it do?',
  },
  session2: {
    path: 'https://workshops.ivia.ch/eth-rat/session2/',
    image: session2,
    description: 'What AI tools are available, which tasks can they solve, and how to use them?',
  },
  session3: {
    path: 'https://workshops.ivia.ch/eth-rat/session3/',
    image: session3,
    description: 'What are the risks of AI and how can they be mitigated?',
  },
  session4: {
    path: 'https://workshops.ivia.ch/eth-rat/session4/',
    image: session4,
    description: 'How to use AI responsibly and effectively in practice?',
  },
  miro: {
    path: 'https://miro.com/app/board/uXjVKmQbFcM=/?share_link_id=520421939340',
    image: miro_logo,
    description: 'Interactive Miro Board for the workshop',
  },
  slido: {
    path: 'https://app.sli.do/event/hy3G1a5THpJ8dPZHYYjtVo',
    image: slido,
    description: 'Q&A and feedback on Slido',
  }
};

const sections = [
  {
    header: 'Day 1 | Introduction',
    sessions: [
      {
        title: 'Intro to AI',
        session: sessionsData.session1,
      },
      {
        title: 'Deep dive into AI tools',
        session: sessionsData.session2,
      },
    ],
  },
  {
    header: 'Day 2 | Interactive Session',
    sessions: [
      {
        title: 'Miro Board',
        session: sessionsData.miro,
      },
      {
        title: 'Deep dive into AI tools',
        session: sessionsData.slido,
      },
    ],
  },
  {
    header: 'Day 3 | Outlook',
    sessions: [
      {
        title: 'Responsible / Trustworthy AI',
        session: sessionsData.session3,
      },
      {
        title: 'Best Practices',
        session: sessionsData.session4,
      },
    ],
  },
];

const EthBoard: React.FC = () => {
  const handleCardClick = (url: string) => {
    window.location.href = url;
  };

  return (
    <Layout className={'h-screen'}>
      <Header style={{ height: 'auto', lineHeight: '18pt', padding: '0 25px' }}>
        <Row justify="center" align="middle" style={{ height: '100%' }}>
          <WorkInProgress />
        </Row>
        {/* <Row justify="center" align="middle" style={{ height: '100%' }}>
          <Typography.Title level={1} style={{ fontWeight: 400 }}>
            ETH-Rat Workshop
          </Typography.Title>
        </Row> */}
      </Header>
      <Content className={"overflow-y-scroll"}>
        <Row justify="center" align="middle" style={{ height: 'auto', marginTop: '2rem' }}>
          <Typography.Title level={1} style={{ fontWeight: 400 }}>
            ETH-Rat Workshop
          </Typography.Title>
        </Row>
        <Row justify="center" style={{ margin: 48 }}>
          {sections.map((section, sectionIndex) => (
            <div key={sectionIndex} style={{ marginBottom: 32, width: '100%' }}>
              <Typography.Title level={2} style={{ marginBottom: 24 }}>
                {section.header}
              </Typography.Title>
              <Row gutter={[32, 32]} justify="center">
                {section.sessions.map((sessionInfo, index) => (
                  <Col key={index}>
                    <Card
                      hoverable
                      className={'shadow-md'}
                      cover={
                        <img
                          src={sessionInfo.session.image}
                          alt={'Card Header'}
                          style={{
                            height: 150,
                            objectFit: 'cover',
                          }}
                        />
                      }
                      onClick={() => handleCardClick(sessionInfo.session.path)}
                      style={{ width: 300 }}
                    >
                      <Meta title={sessionInfo.title} description={sessionInfo.session.description} />
                    </Card>
                  </Col>
                ))}
              </Row>
            </div>
          ))}
        </Row>

        <Flex justify={"space-around"} style={{ margin: 48 }}>
          <Flex style={{ maxWidth: 1000 }} vertical justify={"center"} align={"center"} wrap={true} gap={32}>
            <Typography.Title level={2}>Outline</Typography.Title>
            <Card className={"shadow-md workshop-day"} hoverable title={"Do, 14.11."}>
              <table className={"table-fixed border-spacing-x-5 border-spacing-y-1 border-separate"}>
                <tbody>
                  <tr>
                    <td>09:30 - 10:00</td>
                    <td className={"text-left sec-col"}>Welcome Coffee</td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>10:00 - 10:30</td>
                    <td className={"text-left"}>Welcome Remarks</td>
                    <td className='text-right'>Alex</td>
                  </tr>
                  <tr>
                    <td>10:30 - 11:00</td>
                    <td className={"text-left"}>Introduction</td>
                    <td className='text-right'>Menna</td>
                  </tr>
                  <tr>
                    <td>11:00 - 12:00</td>
                    <td className={"text-left"}>"What is AI?"</td>
                    <td className='text-right'>Thilo</td>
                  </tr>
                  <tr>
                    <td>12:00 - 12:30</td>
                    <td className={"text-left"}>Hands-on Session: AI Explained</td>
                    <td className='text-right'>Thilo & Rita</td>
                  </tr>
                  <tr className='schedule-break'>
                    <td>12:30 - 13:15</td>
                    <td className={"text-left"}>Lunch Break (45 min)</td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>13:15 - 14:00</td>
                    <td className={"text-left"}>Developing AI Applications with Domain Experts</td>
                    <td className='text-right'>Menna & Rita</td>
                  </tr>
                  <tr>
                    <td>14:00 - 14:45</td>
                    <td className={"text-left"}>Commercial AI Tools Overview</td>
                    <td className='text-right'>Matthias</td>
                  </tr>
                  <tr className='schedule-break'>
                    <td>14:45 - 15:00</td>
                    <td className={"text-left"}>Coffee Break (15 min)</td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>15:00 - 15:30</td>
                    <td className={"text-left"}>Data Privacy and AI</td>
                    <td className='text-right'>Matthias & Thilo</td>
                  </tr>
                  <tr>
                    <td>15:30 - 16:30</td>
                    <td className={"text-left"}>AI Use Cases in ETH Rat</td>
                    <td className='text-right'>Rita & Menna</td>
                  </tr>
                  <tr>
                    <td>16:30 - 17:00</td>
                    <td className={"text-left"}>Forming Breakout Groups</td>
                    <td className='text-right'>Menna</td>
                  </tr>
                </tbody>
              </table>
            </Card>


            <Card className={"shadow-md workshop-day"} hoverable title={"Mo, 18.11."}>
              <table className={"table-fixed border-spacing-x-5 border-spacing-y-1 border-separate"}>
                <tbody>
                  <tr>
                    <td>10:00 - 10:30</td>
                    <td className={"text-left"}>Welcome and Briefing for Workshop Day (multiple parrallel sessions)</td>
                    <td>Menna & Alex</td>
                  </tr>
                  <tr>
                    <td>10:30 - 11:30</td>
                    <td className={"text-left"}>Workshop Group 1: AI Assistants & Meeting Augmentation</td>
                    <td className='text-right'>Menna</td>
                  </tr>
                  <tr>
                    <td>11:30 - 12:30</td>
                    <td className={"text-left"}>Workshop Group 2: Terminfindung Tool</td>
                    <td className='text-right'>Alex?</td>
                  </tr>
                  <tr className='schedule-break'>
                    <td>12:30 - 13:15</td>
                    <td className={"text-left"}>Lunch Break (45 min)</td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>13:15 - 14:15</td>
                    <td className={"text-left"}>Workshop Group 3: AI-Powered Forms & Workflows</td>
                    <td className='text-right'>Matthias</td>
                  </tr>
                  <tr>
                    <td>14:15 - 15:15</td>
                    <td className={"text-left"}>Workshop Group 4: Translation Tools</td>
                    <td className='text-right'>Thilo</td>
                  </tr>
                  <tr>
                    <td>15:15 - 16:15</td>
                    <td className={"text-left"}>Workshop Group 5: Visualization and Summarization</td>
                    <td className='text-right'>Rita</td>
                  </tr>
                </tbody>
              </table>
            </Card>

            <Card className={"shadow-md workshop-day"} hoverable title={"Do, 12.12."}>
              <table className={"table-fixed border-spacing-x-5 border-spacing-y-1 border-separate"}>
                <tbody>
                  <tr>
                    <td>10:00 - 10:30</td>
                    <td className={"text-left"}>Presentation Setup and Preparation</td>
                    <td className='text-right'>IVIA & Working Groups</td>
                  </tr>
                  <tr>
                    <td>10:30 - 12:30</td>
                    <td className={"text-left"}>Presentation of Working Group Results</td>
                    <td className='text-right'>Moderation by IVIA</td>
                  </tr>
                  <tr className='schedule-break'>
                    <td>12:30 - 13:15</td>
                    <td className={"text-left"}>Lunch Break (45 min)</td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>13:15 - 14:15</td>
                    <td className={"text-left"}>Biases and Self-Consistency in LLMs</td>
                    <td className='text-right'>Rita & Thilo</td>
                  </tr>
                  <tr>
                    <td>14:15 - 15:15</td>
                    <td className={"text-left"}>Responsible AI & Best Practices</td>
                    <td className='text-right'>Menna & Matthias</td>
                  </tr>
                  <tr>
                    <td>15:15 - 16:15</td>
                    <td className={"text-left"}>Closing Remarks and Outlook</td>
                    <td className='text-right'>Alex</td>
                  </tr>
                </tbody>
              </table>
            </Card>
          </Flex>
        </Flex>
      </Content>
      {/* CENTER THE FOOTER VERTICALLY */}
      <Footer style={{}}>
        <span>
          Made with ♥ by <a href="https://ivia.ch"><img className='ivia-logo' src={IVIA_LOGO} alt="IVIA Logo" /></a>
        </span>
      </Footer>
    </Layout >
  );
};

export default EthBoard;
