// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `footer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  /* Adjust height as necessary */
  background-color: #f0f2f5;
  padding: 1rem 0;
}
footer span {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
footer img {
  vertical-align: middle;
  height: 25px;
}

.schedule-break td {
  font-style: italic;
}

.workshop-day {
  width: 620px;
}
.workshop-day td:nth-child(1) {
  min-width: 85px;
}
.workshop-day td:nth-child(2) {
  padding-left: 10px;
  width: 260px;
}
.workshop-day td:nth-child(3) {
  text-align: right;
  min-width: 150px;
}`, "",{"version":3,"sources":["webpack://./src/pages/ETH-Rat/EthBoard.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,aAAA;EACA,+BAAA;EACA,yBAAA;EACA,eAAA;AACF;AACE;EACE,aAAA;EACA,mBAAA;EACA,WAAA;AACJ;AAEE;EACE,sBAAA;EACA,YAAA;AAAJ;;AAKE;EACE,kBAAA;AAFJ;;AAMA;EACE,YAAA;AAHF;AAKE;EACE,eAAA;AAHJ;AAME;EACE,kBAAA;EACA,YAAA;AAJJ;AAOE;EACE,iBAAA;EACA,gBAAA;AALJ","sourcesContent":["footer {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  height: 100px;\n  /* Adjust height as necessary */\n  background-color: #f0f2f5;\n  padding: 1rem 0;\n\n  span {\n    display: flex;\n    align-items: center;\n    gap: 0.5rem;\n  }\n\n  img {\n    vertical-align: middle;\n    height: 25px;\n  }\n}\n\n.schedule-break {\n  td {\n    font-style: italic;\n  }\n}\n\n.workshop-day {\n  width: 620px;\n\n  td:nth-child(1) {\n    min-width: 85px;\n  }\n\n  td:nth-child(2) {\n    padding-left: 10px;\n    width: 260px;\n  }\n\n  td:nth-child(3) {\n    text-align: right;\n    min-width: 150px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
