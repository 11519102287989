import React from 'react';

import BudibaseForm from 'Components/ContactForm/BudibaseForm/BudibaseForm';

const EthRatWorkshopUmfrage = () => {
  return (
    <BudibaseForm height={3600} MT={`-10px`} budibaseFormLink="https://budibase.ivia.ethz.ch/embed/eth-rat-workshop-umfrage" />
  );
};

export default EthRatWorkshopUmfrage;
