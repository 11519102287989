import React from 'react';

const WorkInProgress = () => {
  return (
    <div style={styles.container}>
      <div style={styles.banner}>
        <span style={styles.text}>!!! This page is still under development and <b>work in progress</b> !!!</span>
        {/* <span style={styles.text}>!!! This page is still <b>work in progress</b> !!!</span> */}
      </div>
    </div>
  );
};

const styles: { [key: string]: React.CSSProperties } = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    padding: '20px',
    boxSizing: 'border-box'
  },
  banner: {
    width: '100%', // Make the banner span the full width of the parent
    padding: '10px 20px',
    border: '10px solid transparent',
    borderImageSource: 'repeating-linear-gradient(30deg, red, red 5px, white 5px, white 10px)',
    borderImageSlice: 1,
    backgroundColor: 'white',
    textAlign: 'center' as 'center',
    borderRadius: '8px',
    boxSizing: 'border-box',
  },
  text: {
    fontSize: '18px',
    fontWeight: 'bold',
    lineHeight: '1px'
  },
};

export default WorkInProgress;
