// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App-logo {
  width: 300px;
  height: auto;
}`, "",{"version":3,"sources":["webpack://./src/pages/Root/RootPage.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,YAAA;AACF","sourcesContent":[".App-logo {\r\n  width: 300px;\r\n  height: auto;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
