import React, {FunctionComponent, PropsWithChildren} from 'react';
import {ConfigProvider, theme} from "antd";

interface Props {
}

const Providers: FunctionComponent<PropsWithChildren<Props>> = ({children}) => {

  return (
    <ConfigProvider
      theme={{
        algorithm: theme.defaultAlgorithm,
        components: {
          Typography: {
            titleMarginTop: 0,
            titleMarginBottom: 0,
          },
          Layout: {
            headerBg: 'rgb(255,255,255)',
            siderBg: '#F3F5F5',
          },
          Menu: {
            itemBg: '#F3F5F5',
          },
        },
      }}
    >
      {children}
    </ConfigProvider>
  );
};

export default Providers;
